<template>
  <div class="lsrw-content">
    <div class="lsrwInfor center">
      <bread-crumb v-if="isShowBread"></bread-crumb>
      <list-show :tabId="tabId" :zrzyDyId="zrzyDyId"></list-show>
    </div>
  </div>

</template>
<script>
import breadCrumb from '@/components/common/breadCrumb';
import historyCard from './components/historyCard';
import ListShow from "@/views/kpzsInfor/components/ListShow";

export default {
  components: {
    ListShow,
    breadCrumb, historyCard
  },
  props: {
    tabId: {
      type: String,
      default: () => ''
    },
    zrzyDyId: {
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
      isShowBread:false,
    }
  },

}
</script>

<style lang="less">
.lsrwInfor {
  padding-bottom: 16px;

  .title {
    padding: 17px 0px 20px 0px;

    p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
  }

  .con-detail {
    width: 100%;
    background: #FFFFFF;
    min-height: 300px;
    padding: 20px 0px 22px 0px;
    box-sizing: border-box;

    .pagination {
      text-align: center;
      margin: 10px 0 !important;

      .el-pagination {
        font-weight: 500;
      }
    }
  }
}
</style>

